
import { Options, Vue } from 'vue-class-component';
import { CompanyClient, CompanyManagerUserClient, EmployeeClient } from '@/services/Services';
import editCustomerModal from '../modals/editCustomerModal.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {}
})
export default class CompanyEdit extends Vue {

    company: OM.Company = new OM.Company();
    companyList: OM.TextIdentifier[] = [];
    customers: OM.CustomerListVM[] = [];
    
    companyIdentifier: string = "";
    loaded: boolean = false;
    roles = VM.Roles;

    created() {
        this.companyIdentifier = this.$route.params.identifier.toString();

        this.init();
    }

    init() {
        Promise.all([
            CompanyClient.getById(this.companyIdentifier),
            EmployeeClient.getAllByCompanyIdentifier(this.companyIdentifier),
            CompanyClient.getAsTextIdentifierList()
        ])
        .then(xs => {
            this.company = xs[0];
            this.customers = xs[1];
            this.companyList = xs[2];
            this.loaded = true;
        })
    }

    addUser(role: string){
        var createCustomer = new OM.CustomerListVM();
        createCustomer.role = role;
        createCustomer.company = {
            identifier: this.company.identifier,
            text: this.company.companyName
        }
        this.editCustomer(createCustomer);
    }

    editCustomer(createCustomer: OM.CustomerListVM){
        this.$opModal.show(editCustomerModal, {
            model: createCustomer, 
            companyList: this.companyList,
            callback: (customer: OM.CustomerListVM) => {
                var isNew = !customer.identifier;
                
                if(isNew)
                    this.createCustomerCallback(customer);
                else
                    this.editCustomerCallback(customer);
            }
        })
    }

    createCustomerCallback(customer: OM.CustomerListVM){
        var customerRegisterData: OM.CompanyManagerRegisterData = {
            companyIdentifier: customer.company.identifier,
            preferredCulture: customer.preferredCulture,
            username: customer.username,
            password: customer.password,
            companyName: ""
        };

        var isManager = customer.role == this.roles.CompanyManager;

        var prom = null;
        if(isManager)
            prom = CompanyManagerUserClient.register(customerRegisterData);
        else
            prom = EmployeeClient.register(customerRegisterData);

        prom
        .then( () => {
            this.$opModal.closeAll();
            this.init();
        })
        .catch(x => {
            console.log(x);
        })
    }

    editCustomerCallback(customer: OM.CustomerListVM){
        var UpdateCustomerVM: OM.UpdateCustomerVM = {
            customerIdentifier: customer.identifier,  
            companyIdentifier: customer.company.identifier,
            preferredCulture: customer.preferredCulture,
            username: customer.username,
            password: customer.password
        };

        EmployeeClient.updateCustomer(UpdateCustomerVM)
        .then( () => {
            this.$opModal.closeAll();
            this.init();
        })
    }
}
