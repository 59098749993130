
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({})
export default class editCustomerModal extends Vue {

    @Prop({
        default: () => new OM.CustomerListVM()
    }) model: OM.CustomerListVM;

    @Prop({
        default: () => []
    }) companyList: OM.TextIdentifier[];

    @Prop() customerType: string;

    localModel: OM.CustomerListVM = new OM.CustomerListVM();

    @Prop() callback: (customer: OM.CustomerListVM) => void;

    created() {
        this.localModel = this.model;
    }

    get disabled(){
        return !this.localModel.company.identifier.trim() || !this.localModel.username.trim() || !this.localModel.preferredCulture
            || (!this.localModel.password && !this.localModel.identifier);
    }

    save() {
        this.callback(this.localModel);
    }
    
}
